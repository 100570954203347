#contact {
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  -webkit-clip-path: polygon(0 12vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 12vh, 100% 0, 100% 100%, 0 100%);
  padding: 15rem 0 10rem 0;
  margin-top: -11rem;
  margin-bottom: -1px;
  color: $white-color;

  @include respond(tab-land) {
    padding: 10rem 0;
    clip-path: none;
    margin-top: 0;
    -webkit-clip-path: none;
  }

  .contact-form {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 0 2rem;
    backface-visibility: hidden;
    @include respond(phone) {
      max-width: 100%;
      padding: 0 20px;
    }

    &__text {
      margin-bottom: 2.5rem;
      font-size: 2.4rem;
      @include respond(phone) {
        font-size: 2rem;
      }
    }

    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      @include respond(phone) {
        margin-bottom: 15px;
      }
    }

    button[type="submit"] {
      border: none;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 500;
      background: linear-gradient(
        45deg,
        $primary-color,
        $dark-blue-text,
        $secondary-color
      );
      background-size: 200%;
      border-radius: 25px;
      transition: background 0.5s ease-in-out;
      padding: 10px 20px;

      &:hover {
        animation: glow 3.5s linear infinite;
      }
      @keyframes glow {
        0% {
          background-position: 0%;
        }
        50% {
          background-position: 100% 100%;
        }
        100% {
          background-position: 0% 0%;
        }
      }
    }
  }
}
