#skills {
  @include applyThemeColors();
  margin-top: -10rem;
  padding-top: 15rem;

  @include respond(tab-land) {
    margin-top: 0;
    padding-top: 5rem;
  }

  .skills-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;

    img {
      width: 75px;
      height: 75px;
    }

    .skill {
      @include applyThemeColors();
      padding: 10px 5px;
      border-radius: 10px;
      text-align: center;
      margin-bottom: 2rem;
      position: sticky;

      &:hover::after {
        content: attr(data-skill-name);
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translate(-50%, -100%);
        background-color: var(--theme-color);
        color: var(--theme-bg);
        padding: 0.5rem;
        border-radius: 4px;
        font-size: 12px;
        z-index: 1;
      }

      &:hover::before {
        content: "";
        position: absolute;
        top: calc(0% + 5px);
        left: 50%;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: var(--theme-color) transparent transparent transparent;
      }
    }

    .skill:nth-child(odd) {
      top: -15vh;
    }

    .skill:nth-child(even) {
      top: 5vh;
    }

    .skill p {
      margin-bottom: 0;
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
