.toggle-wrapper {
  display: flex;
  justify-content: center;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon-o {
  color: $white-color;
}

.fa-sun-o {
  color: yellow;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
  background-color: var(--theme-bg);
}
